import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from "@mui/material";

const Etudiant = (props) => {
  const {
    universite,
    filiere,
    annee,
    setUniversite,
    setFiliere,
    setAnnee,
    msgUniversite,
    msgFiliere,
    msgAnnee,
  } = props;

  return (
    <>
      <TextField
        type="text"
        name="universite"
        label="Université"
        className="w-100"
        value={universite}
        onChange={e => {
          setUniversite(e.target.value)
        }}
        placeholder="Ex : Marien NGOUABI"
        variant="outlined"
        required
        error={Boolean(msgUniversite)}
        helperText={msgUniversite}
        style={{marginBottom: '20px'}}
      />

      <TextField
        type="text"
        name="filiere"
        label="Filière"
        className="w-100"
        value={filiere}
        onChange={e => {
          setFiliere(e.target.value)
        }}
        placeholder="Ex : Informatique"
        variant="outlined"
        required
        error={Boolean(msgFiliere)}
        helperText={msgFiliere}
        style={{marginBottom: '20px'}}
      />

      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">En quantième année êtes vous ?</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={annee}
          label="En quantieme annee etes vous ?"
          onChange={e => setAnnee(e.target.value)}
        >
          <MenuItem value="premiere_annee">1ère annee</MenuItem>
          <MenuItem value="deuxieme_annee">2ème annee</MenuItem>
          <MenuItem value="troisieme_annee">3ème annee</MenuItem>
          <MenuItem value="quatrieme_annee">4ème annee</MenuItem>
          <MenuItem value="cinquieme_annee">5ème annee</MenuItem>
        </Select>
        <p style={{ color: "red" }}>{msgAnnee}</p>
      </FormControl>
    </>
  );
};

export default Etudiant;
