import React from "react";
import choose from "./Mask.jpg";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function ChooseHomeTeacher() {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  return (
    <section className="choose-area">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 ">
            <div>
              <img src={choose} style={{width: '100%',height: '100%'}} alt="choose" />
            </div>
          </div>
          <div className="col-lg-6 home-choose mt-lg-4 mt-md-4 mt-sm-n4">
            <div className="home-choose-content">
              <div>
                <h2>Voulez-vous faire partie de nos professeurs ?</h2>
                <p>
                  Vous avez des compétences pour encadrer à domicile et que vous
                  avez soit du mal à trouver des clients , soit vous voulez
                  avoir davantages des clients
                </p>
              </div>

              <div className="row">
              <h6 className="text-center mb-4 text-decoration-underline">Avantages d'être professeur dans Home Teacher</h6>
              <p>Veuillez cliquer sur chaque point pour voir plus d'informations</p>
                <div className="col-12">
                  <ul className="choose-list-left"> 
                  <Grid item>
                    <ClickAwayListener onClickAway={() => setOpen1(false)}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={open1}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Les professeurs enregistrés ont accès à une plateforme en ligne dédiée où ils peuvent créer leur profil, ajouter leurs compétences, leur expérience et leur disponibilité. Cela facilite la visibilité et la promotion de leurs services auprès des parents et des familles à la recherche d'encadrement."
                        >
                          <li onClick={() => setOpen1(!open1)}>
                            <i className="flaticon-check-mark"></i>
                            Accès à une plateforme en ligne
                          </li>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Grid>

                  <Grid item>
                    <ClickAwayListener onClickAway={() => setOpen2(false)}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={open2}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Home Teacher met en relation les professeurs avec des ménages ayant des enfants à encadrer. Grâce à l'algorithme de mise en relation, les enseignants peuvent être en contacts avec les familles correspondant à leurs compétences et à leurs disponibilités, ce qui leur permet de trouver des clients de manière efficace."
                        >
                          <li onClick={() => setOpen2(!open2)}>
                            <i className="flaticon-check-mark"></i>
                            Mise en relation avec des clients potentiels
                          </li>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Grid>

                  <Grid item>
                    <ClickAwayListener onClickAway={() => setOpen3(false)}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={open3}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="En tant que professeur de Home Teacher, les enseignants peuvent définir leurs propres horaires et disponibilités. Cela leur offre une flexibilité accrue pour concilier leur activité d'encadrement avec leurs autres engagements professionnels ou personnels."
                        >
                          <li onClick={() => setOpen3(!open3)}>
                            <i className="flaticon-check-mark"></i>
                            Flexibilité et gestion du temps
                          </li>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Grid>
  
                  <Grid item>
                    <ClickAwayListener onClickAway={() => setOpen4(false)}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={open4}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Home Teacher peut fournir un soutien administratif aux enseignants en gérant les aspects logistiques tels que la facturation, les paiements et la communication avec les familles. Cela permet aux enseignants de se concentrer sur l'enseignement et d'alléger la charge administrative associée à la recherche de clients et à la gestion des paiements."
                        >
                          <li onClick={() => setOpen4(!open4)}>
                            <i className="flaticon-check-mark"></i>
                            Support administratif
                          </li>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Grid>

                  <Grid item>
                    <ClickAwayListener onClickAway={() => setOpen5(false)}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={open5}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="En rejoignant Home Teacher, les enseignants ont l'occasion d'avoir un impact direct sur l'éducation des enfants en leur fournissant un encadrement personnalisé à domicile. Ils peuvent contribuer à améliorer les performances scolaires des élèves et à les aider à atteindre leurs objectifs éducatifs."
                        >
                          <li onClick={() => setOpen5(!open5)}>
                            <i className="flaticon-check-mark"></i>
                            Opportunité d'avoir un impact éducatif
                          </li>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Grid>
                  </ul>
                </div>
                <Link to="/registerProfesseur" className="box-btn w-50">
                  Rejoignez-nous
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
