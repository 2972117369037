import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../utils/config'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    if(email === ''){
        setErrorMsg("L'email est obligatoire")
        setShowBtn(false)
    }else{
      if(!validateEmail(email)){
        setErrorMsg("L'email n'est pas valide")
        setShowBtn(false)
      }else{
        setErrorMsg('')
        setShowBtn(true)
      }
    }
  }, [email])

  useEffect(() => {
    setErrorMsg('')
  }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${config.apiUrl}/comptes/users/envoyer_mail/`;

    const data = { email };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        setErrorMsg(data.error)
      } else {
        // Gérer la réponse en cas de succès
        toast.success(data.success, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        
      }
      setIsLoading(false);
    })
    .catch((error) => console.log("Erreur => ", error));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <section className="signup-area">
        
        <div className="container">
          <div className="row align-items-center">
            <center>
              <div className="col-lg-6 col-12">
                <div className="signup-form">
                  <h2 style={{fontFamily: 'arial'}}>Rentrez votre email</h2>
                  <form>
                    {/* <InputCustom
                      label="Email"
                      value={email}
                      change={setEmail}
                      type="email"
                      placeholder="Ex : John@example.com"
                    /> */}
                    <TextField
                        type="email"
                        name="email"
                        label="Email"
                        className="w-100"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                        variant="outlined"
                        required
                        error={Boolean(errorMsg)}
                        helperText={errorMsg}
                        style={{marginBottom: '20px'}}
                      />
                    <div className="col-lg-12">
                      {
                        showBtn &&
                        <LoadingButton
                            loading={isLoading}
                            variant='contained'
                            sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2}}
                            loadingIndicator={<CircularProgress color="inherit" size={30} />}
                            onClick={handleSubmit}
                        >
                            Envoyer
                        </LoadingButton> 
                      }
                    </div>
                  </form>
                </div>
              </div>
            </center>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
