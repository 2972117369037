import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import config from '../../utils/config'


const NavBar = () => {
  const [btnShow, setBtnShow] = useState(false);
  const onCloseNav = () => setBtnShow(false);
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem("user");
    toast.info("Déconnexion réussie", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    navigate("/")
    fetch(`${config.clientAdmin}/?logout=yes`, { method: 'GET' })
    .then(response => {
      // Vous pouvez gérer la réponse ici si nécessaire
      console.log('Requête envoyée en arrière-plan');
    })
    .catch(error => {
      console.error('Erreur lors de l\'envoi de la requête en arrière-plan:', error);
    });
  };

  const tableauBord = () => {
      const CryptoJS = require('crypto-js');
      const dataToEncrypt = localStorage.getItem("user");
      const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, 'R#i$5&@9lP!s2*').toString();
      const url = `${config.clientAdmin}/?data=${encodeURIComponent(encryptedData)}`;
      window.location.href = url;
  }

  return (
    <div className="navbar-area">
      <div className="mobile-nav mean-container">
        <div className="mean-bar" style={{height: 80}}>
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <a
            href="#nav"
            className={
              btnShow ? "meanmenu-reveal" : "meanmenu-reveal meanclose"
            }
            style={
              btnShow
                ? {
                    right: 0,
                    left: "auto",
                    textAlign: "center",
                    textIndent: "0px",
                    fontSize: "18px",
                    position: "absolute",
                    marginTop:13
                  }
                : {marginTop:13}
            }
            onClick={() => setBtnShow(!btnShow)}
          >
            {!btnShow ? (
              <span>
                <span>
                  <span></span>
                </span>
              </span>
            ) : (
              "x"
            )}
          </a>
          
          {btnShow && (
            <nav className="mean-nav">
              <ul className="navbar-nav text-right">
                <li className="nav-item">
                  <Link to="/" className="nav-link " style={{marginTop: 20}} onClick={onCloseNav}>
                    Accueil
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/offres" className="nav-link " onClick={onCloseNav}>
                    Offres
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link " onClick={onCloseNav}>
                    Contactez-nous
                  </Link>
                </li>
                {!localStorage.getItem("user") ? (
                  <>
                    <li className="nav-item">
                      <Link to="/login" className="nav-link" onClick={onCloseNav}>
                        Connexion
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/register"
                        className="nav-link"
                        onClick={onCloseNav}
                      >
                      Inscription
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                  <li className="nav-item">
                    <Link onClick={tableauBord} className="nav-link">
                      Tableau de board
                    </Link>
                  </li>
                  {
                    JSON.parse(localStorage.getItem("user")).typeCompte === "Tuteur" && 
                    <li className="nav-item">
                      <Link
                        to="/registerEleve"
                        className="nav-link"
                        onClick={onCloseNav}
                      >
                        Rechercher un encadreur
                      </Link>
                    </li>
                  }
                  <li className="nav-item">
                    <Link onClick={logout} className="nav-link">
                      Déconnexion
                    </Link>
                  </li>
                  </>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>

      <div className="main-nav">
        <div className="container">
          <nav
            className="navbar navbar-expand-md navbar-light"
            style={{ overflow: "" }}
          >
            <Link className="navbar-brand" style={{height: 90, width: 250}} to="/">
              <Logo />
            </Link>
            <div
              className="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav text-right" style={{ display: "" }}>
                <li className="nav-item">
                  <Link to="/" className="nav-link ">
                    Accueil
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/offres" className="nav-link ">
                    Offres
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link ">
                    Contactez-nous
                  </Link>
                </li>
                {!localStorage.getItem("user") ? (
                  <>
                    <li className="nav-item">
                      <Link to="/login" className="nav-link">
                        Connexion
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/register" className="nav-link">
                        Inscription
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                  <li className="nav-item">
                    <Link onClick={tableauBord} className="nav-link">
                      Tableau de board
                    </Link>
                  </li>
                  {
                    JSON.parse(localStorage.getItem("user")).typeCompte === "Tuteur" && 
                    <li className="nav-item">
                      <Link to="/registerEleve" className="nav-link">
                        Rechercher un encadreur
                      </Link>
                    </li>
                  }
                  <li className="nav-item">
                    <Link onClick={logout} className="nav-link">
                      Déconnexion
                    </Link>
                  </li>
                  </>
                )}


              </ul>
            </div>
          </nav>
        </div>
      </div>
      <ToastContainer />
      <br />
    </div>
  );
};

export default NavBar;