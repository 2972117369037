import { useState, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@mui/material";
import img from '../../../assets/images/closed.jpg'
import config from '../../../utils/config'

const steps = ["Etape 1", "Etape 2", "Etape 3"];

const RegisterProfesseur = () => {
  const navigate = useNavigate();

  /**
   * Attributs de l'etape 1
   */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [ville, setVille] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [statut, setStatut] = useState("");
  const [sexe, setSexe] = useState("");

  /**
   * Attributs de l'etape 2
   */
  //etudiant avec ou sans experience
  const [universite, setUniversite] = useState("");
  const [filiere, setFiliere] = useState("");
  const [annee, setAnnee] = useState("");
  //enseignant, diplome avec ou sans experience
  const [dernierDiplome, setDernierDiplome] = useState("");
  const [infoDiplome, setInfoDiplome] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  /**
   * Attributs de l'etape 3
   */
  const [joursDisponibles, setJoursDisponibles] = useState("");
  const [matieresGeneralesEnseignables, setMatieresGeneralesEnseignables] =
    useState("");
  const [classesGeneralesEnseignables, setClassesGeneralesEnseignables] =
    useState("");
  const [periodeJournee, setPeriodeJournee] = useState("");
  const [pieceJointe, setPieceJointe] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    setIsLoading(true)
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("date_naissance", dateNaissance);
    formData.append("adresse", adresse);
    formData.append("ville", ville);
    formData.append("telephone1", telephone1);
    formData.append("telephone2", telephone2);
    formData.append("statut", statut);
    formData.append("universite", universite);
    formData.append("sexe", sexe);
    formData.append("filiere_etude", filiere);
    formData.append("dernier_diplome", dernierDiplome);
    formData.append("annee_etude", annee);
    formData.append("filiere_dernier_diplome", infoDiplome);
    formData.append("periode_journee", periodeJournee);
    formData.append("email", email);
    formData.append("username", email);
    formData.append("password", password);
    formData.append("jours_disponibles", joursDisponibles);
    formData.append(
      "classes_generales_enseignables",
      classesGeneralesEnseignables
    );
    formData.append(
      "matieres_generales_enseignables",
      matieresGeneralesEnseignables
    );
    formData.append("piece_jointe", pieceJointe);

    const url = `${config.apiUrl}/comptes/register/professeur/`;

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          // localStorage.setItem("user", JSON.stringify(data));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          toast.success('Préinscription réussie !, nous vous contacterons pour le jour de l\'entretien', {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          navigate("/");
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setError("Une erreue est survenue, veuillez réessayer plus tard")
        console.log("Erreur => ", error)
        setIsLoading(false)
      }
      );
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const enDev = false
  return (

    enDev ? 
    (
      <section className="area-404">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-8 error-img">
                                <img src={img} alt="404" />
                                <h4 className='text-center mb-4 mt-n4'>Les inscriptions sont fermées pour l'instant</h4>
                                <Link to="/" className="box-btn">
                                  Retour à l'écran d'accueil
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
    :
    (
    <section className="signup-area">
      <ToastContainer />
      <div className="container ">
        <div className="row align-items-center">
          <div class="col-12 col-md-8 offset-md-2">
            <div class="signup-form">
              <h2 className="text-center" style={{fontFamily: 'arial'}}>Préinscription des Professeurs</h2>
              <LoadingButton />
              <Box sx={{ width: "100%", top: "50%" }}>
                <Stepper sx={{ mt: 4 }} activeStep={activeStep}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {activeStep === steps.length ? (
                  <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Votre compte a bien été crée
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    <form method="post" className="form-group">
                      {activeStep === 0 ? (
                        <Step1
                          firstName={firstName}
                          lastName={lastName}
                          dateNaissance={dateNaissance}
                          adresse={adresse}
                          ville={ville}
                          setError={setError}
                          statut={statut}
                          sexe={sexe}
                          setSexe={setSexe}
                          setFirstName={setFirstName}
                          setLastName={setLastName}
                          setDateNaissance={setDateNaissance}
                          setAdresse={setAdresse}
                          setVille={setVille}
                          setStatut={setStatut}
                          setShowBtn={setShowBtn}
                          telephone1={telephone1}
                          telephone2={telephone2}
                          setTelephone1={setTelephone1}
                          setTelephone2={setTelephone2}
                        />
                      ) : activeStep === 1 ? (
                        <Step2
                          setShowBtn={setShowBtn}
                          statut={statut}
                          universite={universite}
                          setUniversite={setUniversite}
                          dernierDiplome={dernierDiplome}
                          setDernierDiplome={setDernierDiplome}
                          filiere={filiere}
                          setFiliere={setFiliere}
                          annee={annee}
                          setAnnee={setAnnee}
                          infoDiplome={infoDiplome}
                          setInfoDiplome={setInfoDiplome}
                          email={email}
                          setEmail={setEmail}
                          password={password}
                          setPassword={setPassword}
                          confirmPassword={confirmPassword}
                          setConfirmPassword={setConfirmPassword}
                        />
                      ) : (
                        <Step3
                          joursDisponibles={joursDisponibles}
                          setJoursDisponibles={setJoursDisponibles}
                          matieresGeneralesEnseignables={
                            matieresGeneralesEnseignables
                          }
                          setMatieresGeneralesEnseignables={
                            setMatieresGeneralesEnseignables
                          }
                          classesGeneralesEnseignables={
                            classesGeneralesEnseignables
                          }
                          setClassesGeneralesEnseignables={
                            setClassesGeneralesEnseignables
                          }
                          periodeJournee={periodeJournee}
                          setPeriodeJournee={setPeriodeJournee}
                          pieceJointe={pieceJointe}
                          setPieceJointe={setPieceJointe}
                          setShowBtn={setShowBtn}
                          setError={setError}
                        />
                      )}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: 'space-between' }}
                      >
                        {
                          activeStep > 0 &&
                        <LoadingButton
                        sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                        onClick={handleBack}
                        >
                          Précédent
                        </LoadingButton>
                        }
                        <Box sx={{ flex: "1 1 auto" }} />
                        {activeStep === steps.length - 1 ? (
                          showBtn &&
                          <LoadingButton
                            loading={isLoading}
                            variant='contained'
                            loadingIndicator={<CircularProgress color="inherit" size={30} />}
                            sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                            onClick={handleSubmit}
                          >
                            Se préinscrire
                          </LoadingButton>
                        ) : (
                          showBtn && 
                          <LoadingButton
                          sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                          onClick={handleNext}
                        >
                          Suivant
                        </LoadingButton>
                        )}
                      </Box>
                      <p style={{ color: "red" }}>{error}</p>
                    </form>
                  </Fragment>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
  );
};

export default RegisterProfesseur;
