import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from "@mui/material";

const Enseignant = (props) => {
  const {
    dernierDiplome,
    infoDiplome,
    setDernierDiplome,
    setInfoDiplome,
    msgDernierDiplome,
    msgInfoDiplome,
  } = props;

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Quel est votre dernier diplôme ?</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dernierDiplome}
          label="Quel est votre dernier diplome ?"
          onChange={(e) => setDernierDiplome(e.target.value)}
        >
          <MenuItem value="bac">BAC</MenuItem>
          <MenuItem value="bts">BTS</MenuItem>
          <MenuItem value="dut">DUT</MenuItem>
          <MenuItem value="licence">Licence</MenuItem>
          <MenuItem value="master">Master</MenuItem>
        </Select>
      </FormControl>
      <p style={{ color: "red" }}>{msgDernierDiplome}</p>

      <TextField
        type="text"
        name="diplome"
        label="Donnez-nous plus d'infos sur votre diplome"
        className="w-100"
        value={infoDiplome}
        onChange={e => {
          setInfoDiplome(e.target.value)
        }}
        placeholder={`Ex : ${dernierDiplome === 'bac' ? `${dernierDiplome.charAt(0).toUpperCase() + dernierDiplome.slice(1)} Série D` : `${dernierDiplome.charAt(0).toUpperCase() + dernierDiplome.slice(1)} en Informatique`}`}
        variant="outlined"
        required
        error={Boolean(msgInfoDiplome)}
        helperText={msgInfoDiplome}
        style={{marginBottom: '20px'}}
      />
    </>
  );
};

export default Enseignant;
