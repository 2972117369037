import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from '../../../../utils/config'

const Step1 = (props) => {
  const { setShowBtn } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [tuteurExiste, setTuteurExiste] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {

    const url = `${config.apiUrl}/comptes/tuteurExiste`;

    if (user) {
        fetch(
          `${url}/${user.username}`,
          {
            method: "GET",
          }
        )
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if (data.existe) {
            setTuteurExiste(true);
            setShowBtn(true);
          } else {
            setShowBtn(false);
            setTuteurExiste(false);
          }
        })
        .catch((error) => console.log("ERREUR => ", error));
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="text-center mt-4">
      {isLoading ? (
        <h1>Chargement ...</h1>
      ) : (
        <>
          {user && tuteurExiste ? (
            <>
            <h4>Bonjour Mr le tuteur {user.first_name} {user.last_name} </h4>
            <h6>Cliquez sur <q>Suivant</q> afin d'inscrire votre enfant</h6>
            </>
          ) : (
            <>
              <h6>Veuillez vous connecter en tant que tuteur</h6>
              <Link to="/login">
                {" "}
                <Button className="box-btn">Connexion</Button>{" "}
              </Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Step1;
