import React, { useState, useEffect } from 'react';
import InputCustom from '../../InputCustom';
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import config from '../../../utils/config'

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [msgNewPassword, setMsgNewPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [msgConfirmNewPassword, setMsgConfirmNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('q');

  useEffect(() => {
    if(newPassword === ''){
        setMsgNewPassword("Le nouveau mot de passe est obligatoire")
        setShowBtn(false)
      } else{
        if(newPassword.length < 6){
            setMsgNewPassword("Le nouveau mot de passe doit être supérieur à 6 caractères")
            setShowBtn(false)
        } else{
            setMsgNewPassword('')
            if(confirmNewPassword !== newPassword){
                setMsgConfirmNewPassword("Les mots de passe ne correspondent pas")
                setShowBtn(false)
            }else{
                setMsgNewPassword('')
                setMsgConfirmNewPassword('')
                setShowBtn(true)
            }
        }
      }

  }, [newPassword, confirmNewPassword])

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${config.apiUrl}/comptes/users/change-password/`;

    const data = { email, new_password: newPassword };

    setIsLoading(true);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        toast.success(data.success, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/login")
        
      } else {
        setErrorMsg(data.error);
      }
      setIsLoading(false);
    })
    .catch(error => console.error('Erreur :', error));
  };

  useEffect(() => {
    setErrorMsg('')
    setMsgNewPassword('')
    setMsgConfirmNewPassword('')
  }, [])

  return (
    <>
      <section className="signup-area">
        <div className="container">
          <div className="row align-items-center">
            <center>
              <div className="col-lg-6 col-12">
                <div className="signup-form">
                  <h2 style={{fontFamily: 'arial'}}>Réinitialisation du mot de passe : </h2>
                  <form>
                    <InputCustom
                      label="Nouveau mot de passe"
                      value={newPassword}
                      change={setNewPassword}
                      name="new_password"
                      type="password"
                      placeholder="Ex : jesuschrist"
                    />

                    <p style={{ color: "red" }}>{msgNewPassword}</p>
                    <InputCustom
                        type="password"
                        label="Confirmez le mot de passe"
                        name="confirm_new_password"
                        placeholder="Ex : jesuschrist"
                        value={confirmNewPassword}
                        change={setConfirmNewPassword}
                    />
                    <p style={{ color: "red" }}>{msgConfirmNewPassword}</p>
                    

                    <p style={{ color: "red" }}>{errorMsg}</p>
                    <div className="col-lg-12">
                      {
                        showBtn &&
                        <LoadingButton
                            loading={isLoading}
                            variant='contained'
                            sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2}}
                            loadingIndicator={<CircularProgress color="inherit" size={30} />}
                            onClick={handleSubmit}
                        >
                            Changer de mot de passe
                        </LoadingButton> 
                      }
                    </div>
                  </form>
                </div>
              </div>
            </center>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
