import React from 'react'
import logo1 from '../../assets/images/logo.png'
import logo2 from '../../assets/images/logo1.png'

const Logo = () => {
  return (
    <>
      <img src={logo1} className="main-logo" style={{width: '100%',height: '100%', marginLeft: -30, marginTop: 8}} alt="logo" />
      <img
        src={logo2}
        className="white-logo"
        style={{width: '100%',height: '100%',marginLeft: -30,marginTop: 8}}
        alt="logo"
      />
    </>
  )
}

export default Logo