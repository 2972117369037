import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControl, InputLabel, TextField, Select, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import img from '../../../assets/images/en_construction.png'
import config from '../../../utils/config'

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const RegisterTuteur = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [profession, setProfession] = useState("");
  const [ville, setVille] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [msgFirstName, setMsgFirstName] = useState("");
  const [msgLastName, setMsgLastName] = useState("");
  const [msgTelephone1, setMsgTelephone1] = useState("");
  const [msgTelephone2, setMsgTelephone2] = useState("");
  const [msgProfession, setMsgProfession] = useState("");
  const [msgVille, setMsgVille] = useState("");
  const [msgEmail, setMsgEmail] = useState("");
  const [msgPassword, setMsgPassword] = useState("");
  const [msgConfirmPassword, setMsgConfirmPassword] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let formatTelephone = /^(06|05|04)[0-9]{7}$/;
    if (
      firstName !== "" &&
      lastName !== "" &&
      telephone1 !== "" &&
      formatTelephone.test(telephone1) &&
      (formatTelephone.test(telephone2) || telephone2 === "") &&
      email !== "" &&
      validateEmail(email) &&      
      password !== "" &&
      password === confirmPassword &&
      profession !== ""
    ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [
    firstName,
    lastName,
    telephone1,
    email,
    ville,
    password,
    confirmPassword,
    telephone2,
    profession,
  ]);

  useEffect(() => {
    if (firstName === "") {
      setMsgFirstName("Le prénom est obligatoire");
    } else {
      setMsgFirstName("");
    }
  }, [firstName]);

  useEffect(() => {
    if (profession === "") {
      setMsgProfession("La profession est obligatoire");
    } else {
      setMsgProfession("");
    }
  }, [profession]);

  useEffect(() => {
    if (lastName === "") {
      setMsgLastName("Le nom est obligatoire");
    } else {
      setMsgLastName("");
    }
  }, [lastName]);


  useEffect(() => {
    if(email === ''){
      setMsgEmail("L'email est obligatoire")
    }else{
      if(!validateEmail(email)){
        setMsgEmail("L'email n'est pas valide")
      }else{
        setMsgEmail('')
      }
    }
  }, [email])


  useEffect(() => {
    if (ville === "") {
      setMsgVille("La ville est obligatoire");
    } else {
      setMsgVille("");
    }
  }, [ville]);



  useEffect(() => {
    if(password === ''){
      setMsgPassword("Le mot de passe est obligatoire")
    }else{
      if(password.length < 6){
        setMsgPassword("Le mot de passe doit être supérieur à 6 caractères")
      }else{
      setMsgPassword('')
      }
    }
  }, [password])

  useEffect(() => {
    if (confirmPassword !== password) {
      setMsgConfirmPassword("Les mots de passe ne correspondent pas");
    } else {
      setMsgConfirmPassword("");
    }
  }, [confirmPassword, password]);


  useEffect(() => {
    if (telephone1 === "") {
      setMsgTelephone1("Le premier numero de tel est obligatoire");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if (formatTelephone.test(telephone1)) {
          setMsgTelephone1("");
      } else {
          setShowBtn(false)
          setMsgTelephone1("Le format du numéro de téléphone est invalide");
      }
    }
  }, [telephone1]);

  useEffect(() => {
    setError("")
    if (telephone1 === telephone2) {
      setMsgTelephone2("Les deux numéros ne doivent pas être identiques");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if(telephone2 !== ""){
        if (formatTelephone.test(telephone2)) {
            setMsgTelephone2("");
        } else {
            setMsgTelephone2("Le format du numéro de téléphone est invalide");
        }
      }else{
        setMsgTelephone2("");
      }
    }
  }, [telephone1, telephone2, setError]);


  useEffect(() => {
    setMsgFirstName("");
    setMsgLastName("");
    setMsgVille("");
    setMsgTelephone1("");
    setMsgTelephone2("");
    setMsgProfession("");
    setMsgEmail("");
    setMsgPassword("");
    setMsgConfirmPassword("");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("telephone1", telephone1);
    formData.append("telephone2", telephone2);
    formData.append("profession", profession);
    formData.append("ville", ville);
    formData.append("email", email);
    formData.append("username", email);
    formData.append("password", password);
    const url = `${config.apiUrl}/comptes/register/tuteur/`;

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setError(data.message);
        } else {
          localStorage.setItem("user", JSON.stringify(data));
          toast.success('Création de compte avec succès', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          navigate("/");
        }
        setIsLoading(false);
      })
      .catch((error) => console.log("Erreur => ", error));
  };

  const enDev = false

  return (

  enDev ? (
    <section className="area-404">
    <div className="d-table">
        <div className="d-table-cell">
            <div className="container">
                <div className="container text-center">
                    <div className="row">
                        <h4 className='text-center mb-4 mt-n4'>Cette page est en construction, elle sera disponible à partir du 15 Août 2023</h4>
                        <div className="col-lg-8 error-img">
                            <img src={img} alt="404" />
                            <Link to="/" className="box-btn">
                              Retour à l'écran d'accueil
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  ) : (
  <section className="signup-area">
    <ToastContainer />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="signup-form">
          <h2 className="text-center" style={{fontFamily: 'arial'}}>Inscription Tuteur</h2>
              <form method="post">
                <div className="row">
                  <TextField
                    type="text"
                    name="firstName"
                    label="Prénom"
                    className="w-100"
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value)
                    }}
                    placeholder="Ex : Mondesir"
                    variant="outlined"
                    required
                    error={Boolean(msgFirstName)}
                    helperText={msgFirstName}
                    style={{marginBottom: '20px'}}
                  />

                  <TextField
                    type="text"
                    name="lastName"
                    label="Nom"
                    className="w-100"
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value)
                    }}
                    placeholder="Ex : DISSIDI"
                    variant="outlined"
                    required
                    error={Boolean(msgLastName)}
                    helperText={msgLastName}
                    style={{marginBottom: '20px'}}
                  />
                  <TextField
                    type="text"
                    name="telephone1"
                    label="Premier numéro de telephone"
                    className="w-100"
                    value={telephone1}
                    onChange={e => {
                      setTelephone1(e.target.value)
                    }}
                    placeholder="Ex : 064723424"
                    variant="outlined"
                    required
                    error={Boolean(msgTelephone1)}
                    helperText={msgTelephone1}
                    style={{marginBottom: '20px'}}
                  />
                  <TextField
                    type="text"
                    name="telephone2"
                    label="Deuxieme numéro de telephone"
                    className="w-100"
                    value={telephone2}
                    onChange={e => {
                      setTelephone2(e.target.value)
                    }}
                    placeholder="Ex : 055652586"
                    variant="outlined"
                    error={Boolean(msgTelephone2)}
                    helperText={msgTelephone2}
                    style={{marginBottom: '20px'}}
                  />
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    className="w-100"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                    placeholder="Ex : test@example.com"
                    variant="outlined"
                    required
                    error={Boolean(msgEmail)}
                    helperText={msgEmail}
                    style={{marginBottom: '20px'}}
                  />

                  <TextField
                    type="password"
                    name="password1"
                    label="Mot de passe"
                    className="w-100"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value)
                    }}
                    placeholder="Mot de passe"
                    variant="outlined"
                    required
                    error={Boolean(msgPassword)}
                    helperText={msgPassword}
                    style={{marginBottom: '20px'}}
                  />

                  <TextField
                    type="password"
                    name="password2"
                    label="Confirmez le mot de passe"
                    className="w-100"
                    value={confirmPassword}
                    onChange={e => {
                      setConfirmPassword(e.target.value)
                    }}
                    placeholder="Mot de passe"
                    variant="outlined"
                    required
                    error={Boolean(msgConfirmPassword)}
                    helperText={msgConfirmPassword}
                    style={{marginBottom: '20px'}}
                  />
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Dans quelle ville vous trouvez vous ?</InputLabel>
                    <Select
                      className="select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={ville}
                      label="Dans quelle ville vous trouvez vous ?"
                      onChange={e => {
                        setVille(e.target.value)
                      }}
                    >
                      <MenuItem value="Brazzaville">Brazzaville</MenuItem>
                      <MenuItem value="Pointe_Noire">Pointe Noire</MenuItem>
                    </Select>
                    <p style={{ color: "red" }}>{msgVille}</p>
                  </FormControl>

                  <TextField
                    type="text"
                    name="profession"
                    label="Votre profession"
                    className="w-100"
                    value={profession}
                    onChange={e => {
                      setProfession(e.target.value)
                    }}
                    placeholder="Ex : commerçante"
                    variant="outlined"
                    required
                    error={Boolean(msgProfession)}
                    helperText={msgProfession}
                    style={{marginBottom: '20px'}}
                  />

                  <div className="col-lg-12">
                    {
                      showBtn &&
                      <LoadingButton
                      loading={isLoading}
                      variant='contained'
                      loadingIndicator={<CircularProgress color="inherit" size={30} />}
                      sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                      onClick={handleSubmit}
                      >
                        Créer le compte
                      </LoadingButton>
                    }
                  </div>
                  <p style={{ color: "red" }}>{error}</p>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
  
  
  );
};

export default RegisterTuteur;
