import React  from 'react'

function QuerySuccess() {

  return (
    <div className="home-contact-area signup-area">
      <div className="container">
        <div className="row align-items-center">
            <div
              className="signup-form"
              style={{
                display: "flex",
                justifyContent: 'center',
                marginTop: 60
              }}
            >
              <h4 className='text-center'>Nous avons reçu votre demande avec succès, nous vous contacterons dans très peu de temps afin de vous proposer un encadreur qui conviendra 
              à l'élève !! 😊🎉</h4>
            </div>
          </div>
        </div>
    </div>
  )
}

export default QuerySuccess