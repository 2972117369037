import React from "react";
import { Link } from "react-router-dom";
import logo2 from '../../assets/images/logo1.png'

const Footer = () => {
  return (
    <>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-left">
                <Link to="/" className="logo">
                  <img src={logo2} alt="logo" style={{marginLeft: -45}} />
                </Link>
                <p>
                  Home Teacher est une plateforme de mise en relation des encadreurs à domicile et les clients ayant
                  besoins de leurs services, tout en assurant le suivi du travail effectué par le professeur.
                </p>
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61550715852309&mibextid=ZbWKwL" target="__blank">
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@home.teacher57?_t=8fST9OU2jeI&_r=1" target="__blank">
                      <i className="fa-brands fa-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-content fml-15">
                <h2>Liens rapides</h2>
                <ul>
                  <li>
                    <Link to="/registerEleve">
                    <i className="flaticon-next"></i> Rechercher un professeur
                    </Link>
                  </li>
                  <li>
                    <Link to="/registerProfesseur">
                    <i className="flaticon-next"></i> S'inscrire en tant que prof
                    </Link>
                  </li>
                  <li>
                    <Link to="/offres">
                    <i className="flaticon-next"></i> Les offres
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                    <i className="flaticon-next"></i>Nous envoyer un mail
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-content fml-15 fml-20">
                <h2>Nous Appeler</h2>
                <ul>
                  <li>
                    <a href="tel:+242064723424">
                      <i className="flaticon-next"></i> +242 06 472 34 24
                    </a>
                    <br />
                    <a href="tel:+242065126619">
                      <i className="flaticon-next"></i> +242 06 512 66 19
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-area">
        <div className="container">
          <div className="col-lg-12">
            <div className="row">
              <div className="copy">
                <p>
                  Copyright @Hometeacher. Tous droits réservés
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
