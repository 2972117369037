import React from "react";
import image from "./study.png";

export default function Slider() {
  return (
    <div className="home-contact-area signup-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mt-md-1" style={{marginTop: 10}}>
            <h2>Trouver un professeur Particulier facilement</h2>
            <p>
              Home Teacher est une plateforme de mise en relation des professeurs à domicile et les clients potentiels ayant besoin de leurs services.
            </p>
          </div>
          <div className="col-lg-6 ">
            <img src={image} alt="#"/>
          </div>
        </div>
      </div>
    </div>
  );
}
