import React from 'react'
import Etudiant from './Etudiant'
import Enseignant from './Enseignant'
import { useState, useEffect } from 'react'
import Compte from './Compte'

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


const Step2 = (props) => {

  const {statut,setShowBtn, universite,setUniversite,filiere,setFiliere,annee,setAnnee,dernierDiplome,setDernierDiplome,infoDiplome,setInfoDiplome, email,setEmail, password,setPassword,confirmPassword, setConfirmPassword} = props

  const [msgUniversite, setMsgUniversite] = useState('')
  const [msgFiliere, setMsgFiliere] = useState('')
  const [msgAnnee, setMsgAnnee] = useState('')
  const [msgDernierDiplome, setMsgDernierDiplome] = useState('')
  const [msgInfoDiplome, setMsgInfoDiplome] = useState('')

  const [msgEmail, setMsgEmail] = useState('')
  const [msgPassword, setMsgPassword] = useState('')
  const [msgConfirmPassword, setMsgConfirmPassword] = useState('')


  useEffect(() => {
    if(statut === 'etudiant_sans_experience' || statut === 'etudiant_avec_experience'){
      setDernierDiplome('')
      setInfoDiplome('')
      if(universite !== '' && filiere !== '' && annee !== '' && email !== '' && validateEmail(email) && password !== '' && confirmPassword !== '' && password === confirmPassword){
        setShowBtn(true)
      }else{
        setShowBtn(false)
      }
    }else if(statut === 'enseignant' || statut === 'diplome_sans_experience' || statut === 'diplome_avec_experience'){
      setUniversite('')
      setAnnee('')
      setFiliere('')
      if(dernierDiplome !== '' && dernierDiplome !=='----------' && infoDiplome !== '' && email !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword){
        setShowBtn(true)
      }else{
        setShowBtn(false)
      }
    }else{
      setDernierDiplome('')
      setInfoDiplome('')
      setUniversite('')
      setAnnee('')
      setFiliere('')
      if(email !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword){
        setShowBtn(true)
      }else{
        setShowBtn(false)
      }
    }
    // eslint-disable-next-line
  }, [universite,filiere,annee, dernierDiplome, infoDiplome, email, password, confirmPassword, statut])

  useEffect(() => {
    if(email === ''){
      setMsgEmail("L'email est obligatoire")
    }else{
      if(!validateEmail(email)){
        setMsgEmail("L'email n'est pas valide")
      }else{
        setMsgEmail('')
      }
    }
  }, [email])

  useEffect(() => {
    if(password === ''){
      setMsgPassword("Le mot de passe est obligatoire")
    }else{
      if(password.length < 6){
        setMsgPassword("Le mot de passe doit être supérieur à 6 caractères")
      }else{
      setMsgPassword('')
      }
    }
  }, [password])


  useEffect(() => {
    if(confirmPassword !== password){
      setMsgConfirmPassword("Les mots de passe ne correspondent pas")
    }else{
      setMsgConfirmPassword('')
    }
  }, [confirmPassword, password])

  useEffect(() => {
    if(universite === ''){
      setMsgUniversite("L'université est obligatoire")
    }else{
      setMsgUniversite("")
    }
  }, [universite])

  useEffect(() => {
    if(filiere === ''){
      setMsgFiliere("La filiere est obligatoire")
    }else{
      setMsgFiliere("")
    }
  }, [filiere])

  
  useEffect(() => {
    if(annee === '' || annee === '----------'){
      setMsgAnnee("L'année est obligatoire")
    }else{
      setMsgAnnee("")
    }
  }, [annee])

  useEffect(() => {
    if(dernierDiplome === '' || dernierDiplome === '----------'){
      setMsgDernierDiplome("Le dernier diplôme est obligatoire")
    }else{
      setMsgDernierDiplome("")
    }
  }, [dernierDiplome])

  useEffect(() => {
    if(infoDiplome === ''){
      setMsgInfoDiplome("Donnez nous les infos")
    }else{
      setMsgInfoDiplome("")
    }
  }, [infoDiplome])

  useEffect(() => {
    setMsgUniversite('')
    setMsgFiliere('')
    setMsgAnnee('')
    setMsgDernierDiplome('')
    setMsgInfoDiplome('')
    setMsgEmail('')
    setMsgPassword('')
    setMsgConfirmPassword('')
    
  }, [])


  return (
    <div className='text-center mt-4'>
      {
        (statut === 'etudiant_sans_experience' || statut === 'etudiant_avec_experience') ? <Etudiant universite={universite} setUniversite={setUniversite} filiere={filiere} setFiliere={setFiliere} annee={annee} setAnnee={setAnnee} msgUniversite={msgUniversite} msgFiliere={msgFiliere} msgAnnee={msgAnnee} /> 
        : 
        ( (statut === 'enseignant' || statut === 'diplome_sans_experience' || statut === 'diplome_avec_experience') && <Enseignant msgDernierDiplome={msgDernierDiplome} msgInfoDiplome={msgInfoDiplome} dernierDiplome={dernierDiplome} setDernierDiplome={setDernierDiplome} infoDiplome={infoDiplome} setInfoDiplome={setInfoDiplome} /> )
      }
      <Compte msgEmail={msgEmail} msgPassword={msgPassword} msgConfirmPassword={msgConfirmPassword} email={email} password={password} confirmPassword={confirmPassword} setEmail={setEmail} setPassword={setPassword} setConfirmPassword={setConfirmPassword} />
    </div>
  )
}

export default Step2