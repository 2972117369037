import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="home-contact-area signup-area">
      <div className="container">
        <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 100
              }}
            >
              <Link to="/registerProfesseur">
                <Button className="box-btn">Préinscription des professeurs</Button>
              </Link>
              <Link to="/registerTuteur" style={{marginTop: 40}}>
                <Button className="box-btn">Inscription des Tuteurs</Button>
              </Link>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Register;
