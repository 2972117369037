import React from 'react'
import img from '../../assets/images/404.svg'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
      <section className="area-404">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="container text-center">
                        <div className="row">
                            <h4 className='text-center mb-4'>Oups !, cette page n'existe pas</h4>
                            <div className="col-lg-8 error-img">
                                <img src={img} alt="404" />
                                <Link to="/" className="box-btn">
                                  Retour à l'écran d'accueil
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ErrorPage