import React, { Fragment } from "react";
import { TextField } from "@mui/material";

const Compte = (props) => {
  const {
    email,
    password,
    confirmPassword,
    setEmail,
    setPassword,
    setConfirmPassword,
    msgEmail,
    msgPassword,
    msgConfirmPassword,
  } = props;

  return (
    <Fragment>
      <TextField
        type="email"
        name="email"
        label="Email"
        className="w-100"
        value={email}
        onChange={e => {
          setEmail(e.target.value)
        }}
        placeholder="Ex : john@example.com"
        variant="outlined"
        required
        error={Boolean(msgEmail)}
        helperText={msgEmail}
        style={{marginBottom: '20px'}}
      />

      <TextField
        type="password"
        name="password"
        label="Mot de passe"
        className="w-100"
        value={password}
        onChange={e => {
          setPassword(e.target.value)
        }}
        placeholder="Ex : jesuschrist"
        variant="outlined"
        required
        error={Boolean(msgPassword)}
        helperText={msgPassword}
        style={{marginBottom: '20px'}}
      />

      <TextField
        type="password"
        name="confirmPassword"
        label="Confirmez le mot de passe"
        className="w-100"
        value={confirmPassword}
        onChange={e => {
          setConfirmPassword(e.target.value)
        }}
        placeholder="Ex : jesuschrist"
        variant="outlined"
        required
        error={Boolean(msgConfirmPassword)}
        helperText={msgConfirmPassword}
        style={{marginBottom: '20px'}}
      />
    </Fragment>
  );
};

export default Compte;
