import React from 'react'
import { Link } from "react-router-dom";
import flyer1 from '../../assets/images/flyer2.jpg'
import flyer2 from '../../assets/images/flyer3.jpg'
import prof from '../../assets/images/prof.jpg'
import config from '../../utils/config'

function Offer() {

  const url = `${config.clientAdmin}`;

  return (
    <div className="home-contact-area signup-area">
      <div className="container">
          <div className="row align-items-center">
            <div
              className="signup-form"
              style={{
                display: "flex",
                justifyContent: 'center'
              }}
            >
              <h2>Offres</h2>
            </div>
          </div>
      </div>
      <center>
        <br />
        <h3>Inscrivez vous en tant que professeur</h3>
        <div style={{maxWidth: 600, height: 600}}>
          <img src={prof} alt="prof" style={{width: '100%',height: '100%'}}/>
        </div>
        <Link to="/registerProfesseur" className="nav-link">Cliquez sur ce lien</Link>
        <br />
        <h4>Inscrivez votre enfant pour bénéficier de nos services</h4>
        <div style={{maxWidth: 600, height: 600}}>
          <img src={flyer1} alt="flyer1" style={{width: '100%',height: '100%'}}/>
        </div>
        <Link to="/registerEleve" className="nav-link">Cliquez ici pour rechercher un encadreur</Link>
        <div style={{maxWidth: 600, height: 750}}>
          <img src={flyer2} alt="flyer2" style={{width: '100%',height: '100%'}}/>
        </div>
        <br />
        <Link to="/registerEleve" className="nav-link">Cliquez ici pour rechercher un encadreur</Link>
        <br />
        <h3><b>Nos services : </b></h3><br/>
        <h5>1 - 💡 Cours gratuit en Informatique : </h5>
        <p>
        Préparez-les pour un monde numérique en constante évolution. Notre initiation en informatique au moins une fois par mois donne aux élèves des compétences de bases pour réussir dans l'ère moderne.
        </p>
        <br />
        <h5>2 - 📊 Suivi du travail effectué par le professeur : </h5>
        <p>
        Nous vous permettons de voir comment les professeurs travaillent avec vos enfants via cette plateforme. Car la confiance n'exclut pas le contrôle. Pour ce faire il faut aller sur votre tableau bord après être connecté<br/>
        </p>
        <br />
        <h5>3 - 📋 Système de Notation et commentaire: </h5>
        <p>
        Votre avis est essentiel. Notre système de notation et de commentaires vous permet de vous exprimer librement (remarques, plaintes ...) concerant un professeur en particulier. Pour ce faire il faut aller sur votre tableau bord après être connecté<br/>
        </p>
        <br />
        <h5>4 - 📚 Support et Ressources Pédagogiques: </h5>
        <p>
        Nous fournissons un accès à des ressources complémentaires (si elles sont disponibles bien sûre) pour approfondir les connaissances et élargir les horizons<br/>
        </p>
        <br />
        <h5>5 - 📈 Récompenses des éfforts des élèves : </h5>
        <p>
        Chaque effort mérite d'être célébré. C'est l'un de nos objectifs, nous allons nous battre afin de récompenser les progrès des élèves qui travaillent avec nous via des gestes significatives, donnant à vos enfants la confiance nécessaire pour briller.
        </p>

      </center>
    </div>
  )
}

export default Offer