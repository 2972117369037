import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import config from '../../../utils/config'

const steps = ["Etape 1", "Etape 2", "Etape 3", "Etape 4"];

const RegisterEleve = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [classe, setClasse] = useState("");
  const [ecole, setEcole] = useState("");
  const [sexe, setSexe] = useState("");

  const [classeGenerale, setClasseGenerale] = useState("");
  const [matieresGenerales, setMatieresGenerales] = useState("");
  const [lieuTravail, setLieuTravail] = useState("");
  const [ville, setVille] = useState("");
  const [difficulte, setDifficulte] = useState("");
  const [periodeJournee, setPeriodeJournee] = useState("");
  const [joursDisponibles, setJoursDisponibles] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [error, setError] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const [professeurs, setProfesseurs] = useState([])


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naissance", dateNaissance);
    formData.append("classe", classe);
    formData.append("matieres", matieresGenerales);
    formData.append("ecole", ecole);
    formData.append("lieu_travail", lieuTravail);
    formData.append("ville", ville);
    formData.append("difficulte", difficulte);
    formData.append("sexe", sexe);
    formData.append("periode_journee", periodeJournee);
    formData.append("jours_disponibles", joursDisponibles);
    formData.append("tuteur", JSON.parse(localStorage.getItem("user")).id);

    const url = `${config.apiUrl}/comptes/register/eleve/`;

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setError(data.message);
        } else {
          toast.success('Inscription de l\'élève réussie !', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            setProfesseurs(data.professeurs_disponibles)
            setTimeout(() => {
              setIsLoading(false)
              setIsSuccess(true)
            }, [1000])
            //navigate("/querySuccess", {replace:false});
        }
      })
      .catch((error) => console.log("Erreur => ", error));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
      (
      isLoading ?
      <center>
        <div style={{marginBottom: 80}}>
          <CircularProgress sx={{mt: 20}} size={70} />
          <h4 className="mt-2">Recherche des professeurs...</h4>
        </div>
      </center>
      :
      (
      isSuccess ? 
      <section className="area-404">
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="container">
                      <div className="container text-center">
                          <div className="row">
                              <h4 className='text-center mb-4 mt-n4'>
                                {
                                  professeurs.length === 0 ?
                                  "Désolé 😔, nous n'avons pas trouvé de professeurs correspondants à vos critères, mais nous vous contacterons très rapidement pour en discuter" :
                                  `Félicitations 🥳🎉, nous avons trouvé ${professeurs.length} professeur(s) qui correspond(ent) aux critères de votre enfant. Nous vous contacterons très rapidement pour la mise en relation.`
                                }
                              </h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      :
      <section className="signup-area">
          <div className="container">
            <div className="row align-items-center">
              <ToastContainer />
              <div class="col-12 col-md-8 offset-md-2">
                <div class="signup-form">
                <h3 className="text-center">Recherche d'un professeur à domicile</h3>
                  <center>
                  <Box sx={{ width: "100%" }}>
                    <Stepper sx={{ mt: 4 }} activeStep={activeStep}>
                      {steps.map((label, index) => {
                        return (
                          <Step key={index}>
                            <StepLabel></StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>

                    {activeStep === steps.length ? (
                      <Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Patientez 3 secondes
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <form method="post" className="form-group">
                          {activeStep === 0 ? (
                            <Step1 setShowBtn={setShowBtn} />
                          ) : activeStep === 1 ? (
                            <Step2
                              nom={nom}
                              setNom={setNom}
                              prenom={prenom}
                              setPrenom={setPrenom}
                              sexe={sexe}
                              setSexe={setSexe}
                              dateNaissance={dateNaissance}
                              setDateNaissance={setDateNaissance}
                              setShowBtn={setShowBtn}
                              setError={setError}
                              ecole={ecole}
                              setEcole={setEcole}
                            />
                          ) : activeStep === 2 ? (
                            <Step3
                              classeGenerale={classeGenerale}
                              setClasseGenerale={setClasseGenerale}
                              setClasse={setClasse}
                              matieresGenerales={matieresGenerales}
                              setMatieresGenerales={setMatieresGenerales}
                              classe={classe}
                              setError={setError}
                              setShowBtn={setShowBtn}
                            />
                          ) : (
                            <Step4
                              lieuTravail={lieuTravail}
                              ville={ville}
                              setLieuTravail={setLieuTravail}
                              setVille={setVille}
                              difficulte={difficulte}
                              setDifficulte={setDifficulte}
                              periodeJournee={periodeJournee}
                              setPeriodeJournee={setPeriodeJournee}
                              joursDisponibles={joursDisponibles}
                              setJoursDisponibles={setJoursDisponibles}
                              setError={setError}
                              setShowBtn={setShowBtn}
                            />
                          )}

                          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            {
                              activeStep > 0 &&
                              <Button
                              sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                              onClick={handleBack}
                              >
                                Précédent
                              </Button>
                            }
                            <Box sx={{ flex: "1 1 auto" }} />
                            {activeStep === steps.length - 1 ? (
                              showBtn &&
                              <Button
                                sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                                onClick={handleSubmit}
                              >
                                Valider
                              </Button>
                            ) : (
                              showBtn &&
                              <Button
                                sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                                onClick={handleNext}
                              >
                                Suivant
                              </Button>
                            )}
                          </Box>
                          <p style={{ color: "red" }}>{error}</p>
                        </form>
                      </Fragment>
                    )}
                  </Box>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
        )
    )
  
  
  );
};
export default RegisterEleve;
