import { useState, useEffect } from 'react'
import InputCustom from '../../../InputCustom'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

const Step2 = (props) => {
    const { nom , setNom, prenom, setPrenom, dateNaissance, setDateNaissance, setShowBtn, setError, ecole, setEcole, sexe, setSexe } = props

    const [msgNom, setMsgNom] = useState('')
    const [msgPrenom, setMsgPrenom] = useState('')
    const [msgDateNaissance, setMsgDateNaissance] = useState('')
    const [msgEcole, setMsgEcole] = useState('')
    const [msgSexe, setMsgSexe] = useState('')
    
    useEffect(() => {
      let annee = new Date(dateNaissance).getFullYear();

        if(nom !== '' && sexe !== '' && prenom !== '' && dateNaissance !== '' && annee >= 1993 && annee <= 2019 && ecole !== ''){
          setShowBtn(true)
        }else{
          setShowBtn(false)
        }
        // eslint-disable-next-line
      }, [nom,prenom, dateNaissance, ecole,sexe])
    
      useEffect(() => {
        if(nom === ''){
          setMsgNom("Le nom est obligatoire")
        }else{
          setMsgNom('')
        }
      }, [nom])

      useEffect(() => {
        if(sexe === ''){
          setMsgSexe("Le sexe est obligatoire")
        }else{
          setMsgSexe('')
        }
      }, [sexe])

      useEffect(() => {
        if(ecole === ''){
          setMsgEcole("L'ecole est obligatoire")
        }else{
          setMsgEcole('')
        }
      }, [ecole])

      useEffect(() => {
        if(prenom === ''){
          setMsgPrenom("Le prenom est obligatoire")
        }else{
          setMsgPrenom('')
        }
      }, [prenom])

      useEffect(() => {
        if(dateNaissance === ''){
          setMsgDateNaissance("La date de naissance est obligatoire")
        }else{
          let annee = new Date(dateNaissance).getFullYear();
          if (annee >= 1993 && annee <= 2019) {
            setMsgDateNaissance('')
          } else {
            setMsgDateNaissance("L'âge doit être comprise entre 4 et 30 ans");
          }
          
        }
      }, [dateNaissance])

      useEffect(() => {
        setMsgNom('')
        setMsgPrenom('')
        setMsgDateNaissance('')
        setMsgEcole('')
        setMsgSexe('')
        setError('')
        // eslint-disable-next-line
    }, [])


    return (
        <div className='text-center mt-4'>
            <TextField
              type="text"
              name="nom"
              label="Nom(s) de l'élève"
              className="w-100"
              value={nom}
              onChange={e => {
                setNom(e.target.value)
              }}
              placeholder="Ex : NTSOUMOU"
              variant="outlined"
              required
              error={Boolean(msgNom)}
              helperText={msgNom}
              style={{marginBottom: '20px'}}
            />
            <TextField
              type="text"
              name="prenom"
              label="Pénom(s) de l'élève"
              className="w-100"
              value={prenom}
              onChange={e => {
                setPrenom(e.target.value)
              }}
              placeholder="Ex : Marien NGOUABI"
              variant="outlined"
              required
              error={Boolean(msgPrenom)}
              helperText={msgPrenom}
              style={{marginBottom: '20px'}}
            />
            <p>Date de naissance de l'élève</p>
            <TextField
              type="date"
              name="dateNaissance"
              className="w-100"
              value={dateNaissance}
              onChange={e => {
                setDateNaissance(e.target.value)
              }}
              variant="outlined"
              required
              error={Boolean(msgDateNaissance)}
              helperText={msgDateNaissance}
              style={{marginBottom: '20px'}}
            />           
            
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Genre de l'élève</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sexe}
                label="Genre de l'élève"
                onChange={e => setSexe(e.target.value)}
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Feminin">Feminin</MenuItem>
              </Select>
            </FormControl>
            <p style={{ color: "red",textAlign: 'center' }}>{msgSexe}</p>
            
            <TextField
              type="text"
              name="ecole"
              label="Dans quelle école fréquente t-il ?"
              className="w-100"
              value={ecole}
              onChange={e => {
                setEcole(e.target.value)
              }}
              placeholder="Ex : Lumumba"
              variant="outlined"
              required
              error={Boolean(msgEcole)}
              helperText={msgEcole}
              style={{marginBottom: '20px'}}
            />
        </div>
    )
}

export default Step2