import ChooseHomeTeacher from "../ChooseHT";
import Description from "../Description";
import Slider from "../Slider";


const Landing = () => {

  return (
    <>
      <Slider />
      <Description />
      <ChooseHomeTeacher />
    </>
  );
};

export default Landing;