import {useState, useEffect} from "react";
import contact from "../../assets/images/contact.jpg";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from "@mui/material";
import config from '../../utils/config'

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


export default function Contact() {

  const [nom , setNom] = useState("")
  const [email, setEmail] = useState("")
  const [sujet, setSujet] = useState("")
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [msgEmail, setMsgEmail] = useState("");
  const [msgNom, setMsgNom] = useState("");
  const [msgSujet, setMsgSujet] = useState("");
  const [msgMessage, setMsgMessage] = useState("");


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${config.apiUrl}/comptes/contact/`;

    const data = { nom: nom, email: email, sujet : sujet, message:message };
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("DATA => ", data)
          if (data.message === 'success') {
            toast.success('Votre message a été envoyé avec succès !', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            navigate('/')
          } else {
            toast.error('Echec, vérifiez votre connexion internet !', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
          setIsLoading(false);
        })
        .catch((error) => console.log("Erreur => ", error));
    } catch (error) {
      console.log("Erreur : ", error);
    }
  };

  useEffect(() => {
    setError("");
    if (email !== "" && validateEmail(email) && nom !== '' && message !== '' && sujet !== '') {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [email, nom, sujet, message]);

  useEffect(() => {
    if(email === ''){
      setMsgEmail("L'email est obligatoire")
    }else{
      if(!validateEmail(email)){
        setMsgEmail("L'email n'est pas valide")
      }else{
        setMsgEmail('')
      }
    }
  }, [email])

  useEffect(() => {
    if(nom === ''){
      setMsgNom("Le nom est obligatoire")
    }else{
      setMsgNom("")
    }
  }, [nom])

  useEffect(() => {
    if(sujet === ''){
      setMsgSujet("Le sujet est obligatoire")
    }else{
      setMsgSujet("")
    }
  }, [sujet])

  useEffect(() => {
    if(message === ''){
      setMsgMessage("Le message est obligatoire")
    }else{
      setMsgMessage("")
    }
  }, [message])

  useEffect(() => {
    setMsgEmail("");
    setMsgNom("");
    setMsgSujet("");
    setMsgMessage("");
    setError("");
  }, []);


  return (
    <section className="home-contact-area pb-100 pt-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 ps-0">
            <div className="contact-img">
              <img src={contact} alt="contact" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="home-contact-content">
              <h2 style={{fontFamily: 'arial'}}>Que voulez-vous nous dire ?</h2>
              <form id="contactForm">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="nom"
                        id="nom"
                        onChange={e => setNom(e.target.value)}
                        className="form-control"
                        required
                        data-error="Veillez entrez votre nom"
                        placeholder="Votre nom"
                        value={nom}
                      />
                      <div className="help-block with-errors">
                        <p style={{ color: "red",textAlign: 'center' }}>{msgNom}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        id="email"
                        className="form-control"
                        required
                        data-error="Veillez entrez votre email"
                        placeholder="Votre Email"
                        value={email}
                      />
                      <div className="help-block with-errors">
                        <p style={{ color: "red",textAlign: 'center' }}>{msgEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        id="msg_subject"
                        onChange={e => setSujet(e.target.value)}
                        className="form-control"
                        required
                        data-error="Veillez entrer votre sujet"
                        placeholder="Sujet"
                        value={sujet}
                      />
                      <div className="help-block with-errors">
                        <p style={{ color: "red",textAlign: 'center' }}>{msgSujet}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        onChange={e => setMessage(e.target.value)}
                        cols="30"
                        rows="5"
                        required
                        data-error="Ecrivez votre message"
                        placeholder="Votre Message"
                      >{message}</textarea>
                      <div className="help-block with-errors">
                        <p style={{ color: "red",textAlign: 'center' }}>{msgMessage}</p>
                      </div>
                    </div>
                  </div>

                  <p style={{ color: "red",textAlign: 'center' }}>{error}</p>

                  <div className="col-lg-12 col-md-12">
                    {
                      showBtn &&
                      <LoadingButton
                        loading={isLoading}
                        variant='contained'
                        sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2}}
                        loadingIndicator={<CircularProgress color="inherit" size={30} />}
                        onClick={handleSubmit}
                      >
                        Envoyer le message
                      </LoadingButton> 
                    }
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
